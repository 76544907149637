import { addDoc, collection } from "@firebase/firestore"
import { firestore } from "./firebase"


const handleSubmit = async (strData) => {
    const ref = collection(firestore, "email_list");
 
    let data = {
        email: strData
    }
    
    try {
        await addDoc(ref, data)
    } catch (err) {
        console.log(err)
    }
}

export default handleSubmit;