import React from 'react';
import './SectionItem.css'

function SectionItem({ logo, label, isTerminated, onItemClick, isSelected }) {
    return (
            <div className='section-item-container' onClick={() => onItemClick(label)}>
                <div className={'section-item' + (isTerminated ? ' terminated' : '') + (isSelected ? ' selected' : '')}>
                    <img className='section-item-img' src={logo} alt={label + ' company logo'}></img>
                    <p className='section-items-label'>{ label }</p>
                </div>
            </div>
    );
}

export default SectionItem;
