import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'
  import { faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import EmailSubmit from '../emailSubmit/EmailSubmit';

function Footer() {
    return (
        <footer className='footer'>
            <a className='back-to-top' href='#'>
                <FontAwesomeIcon icon={faAngleDoubleUp} size='2x' className='icon-arrow'/>
                <p>back to top</p>
            </a>
            <EmailSubmit />
            <p className='name'>Aeon Research</p>
            <p>© copyright 2023. All rights reserved.</p>
            <div className='icons'>
                <a target='_blank' href='https://www.linkedin.com/company/aeon-rs'>
                    <FontAwesomeIcon icon={faLinkedin} size='2x' className='icon' />
                </a>
                <a target='_blank' href='https://twitter.com/aeon_research'>
                <FontAwesomeIcon icon={faTwitter} size='2x' className='icon' />

                </a>
            </div>
        </footer>
    )
}

export default Footer;
