import React from 'react';
import './Header.css';

function Header({ onClick }) {
    return (
        <header>
            <div>
                <button onClick={(e) => onClick(e.target.innerHTML)} >æon research</button>
            </div>
            <div>
                <button onClick={(e) => onClick(e.target.innerHTML)} className='info-btn'>info</button>
                <button onClick={(e) => onClick(e.target.innerHTML)} >contact</button>
            </div>
        </header>
    );
}

export default Header;
