import React from 'react';
import './SectionItems.css';
import SectionItem from '../sectionItem/SectionItem';

function SectionItems({ data, onItemClick, selectedItem }) {
    return (
        <div className={'section-items-wrapper' + (data.length > 2 ? ' extra-padding' : '')}>
            {data.map((dataItem) => (
                 <SectionItem
                    key={dataItem.label}
                    logo={dataItem.logo}
                    label={dataItem.label}
                    isTerminated={dataItem.isTerminated}
                    onItemClick={onItemClick}
                    isSelected={dataItem === selectedItem}
                />
            ))}
        </div>
    );
}

export default SectionItems;
