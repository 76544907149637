import React from 'react';
import './EmailSubmit.css';
import handleSubmit from './../../firebaseSetup/handleSubmit';
import { useRef, useState } from 'react';
import Spinner from '../spinner/Spinner';
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
 
function EmailSubmit() {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const dataRef = useRef()
 
  const submitHandler = async (e) => {
    setShowSpinner(true);
    e.preventDefault();
    await handleSubmit(dataRef.current.value);
    setShowCheck(true);
    setShowSpinner(false);

    dataRef.current.value = ""
  }

  const onChange = () => {
    setShowCheck(false);
  }
 
  return (
    <div className="email-submit-container">
      <form onSubmit={submitHandler} onChange={onChange}>
        {showSpinner && <Spinner />}
        {showCheck && <FontAwesomeIcon className='check-icon' icon={faCheck} />}
        <p>Get the latest updates:</p>
        <input placeholder='your@email.com' type="email" ref={dataRef} required="required" />
        <button type="submit">Sign Up</button>
      </form>
    </div>
  );
}
 
export default EmailSubmit;
