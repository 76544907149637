import React from 'react';
import { useState } from 'react';

import './Home.css'
import Content from '../components/content/Content';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import InfoOrContact from '../components/info/InfoOrContact';

const initState = { showInfo: false, showContact: false, showMainContent: false };

function Home() {
    const [content, setContent] = useState({ ...initState, showMainContent: true});

    const onClickHeader = (title) => {
        if (title === 'info') {
            setContent({ ...initState, showInfo: true });
            return;
        }

        if (title === 'contact') {
            setContent({ ...initState, showContact: true });
            return;
        }

        setContent({ ...initState, showMainContent: true});
    };

    return (
        <div className="home">
            <div className="container">
                <Header onClick={onClickHeader}/>
                {content.showMainContent && <Content/>}
                {(content.showInfo || content.showContact) && <InfoOrContact isInfo={content.showInfo}/>}

                <Footer />
            </div>
        </div>
    )
}

export default Home;
