import tellersLogo from './../../resources/imgs/tellers_logo.png';
import hexagoLogo from './../../resources/imgs/hexago_logo.png';
import steganoGraphicsLogo from './../../resources/imgs/stegano_graphics_logo.png';
import interactionAiLogo from './../../resources/imgs/interaction_logo.png';
import consultingLogo from './../../resources/imgs/consulting_logo.png';
import liplLogo from './../../resources/imgs/lipl_logo.png';
import svgComposerLogo from './../../resources/imgs/svg_composer_logo.png';
import sonosLogo from './../../resources/imgs/sonos_logo.png';
import snipsLogo from './../../resources/imgs/snips_logo.png';
import bonemetrixLogo from './../../resources/imgs/bonemetrix_logo.png';
import school42Logo from './../../resources/imgs/school_42_logo.png';
import schoolHECLogo from './../../resources/imgs/school_logo.png';

import bonemetrixMd from '../../resources/markdowns/bonemetrix.md';
import consultingMd from '../../resources/markdowns/consulting.md';
import interactionMd from '../../resources/markdowns/interaction.md';
import liplMd from '../../resources/markdowns/lipl.md';
import school42Md from '../../resources/markdowns/school_42.md';
import schoolHECMd from '../../resources/markdowns/school_hec.md';
import snipsMd from '../../resources/markdowns/snips.md';
import sonosMd from '../../resources/markdowns/sonos.md';
import steganoGraphicsMd from '../../resources/markdowns/stegano_graphics.md';
import svgComposerMd from '../../resources/markdowns/svg_composer.md';
import tellersMd from '../../resources/markdowns/tellers.md';
import hexagoMd from '../../resources/markdowns/hexago.md';


let sectionItemsData = [
    {
        sectionTitle: "companies",
        sectionData: [
            {
                logo: tellersLogo,
                label: "tellers.ai",
                isTerminated: false,
                markDown: tellersMd,
            },
            {
                logo: hexagoLogo,
                label: "hexago.app",
                isTerminated: false,
                markDown: hexagoMd,
            },
            {
                logo: steganoGraphicsLogo,
                label: "stegano.graphics",
                isTerminated: false,
                markDown: steganoGraphicsMd,
            },
            {
                logo: interactionAiLogo,
                label: "InterAction.AI",
                isTerminated: true,
                markDown: interactionMd,
            },
        ]
    },
    {
        sectionTitle: "services",
        sectionData: [
            {
                logo: consultingLogo,
                label: "Product & AI Consulting",
                isTerminated: false,
                markDown: consultingMd,
            },
        ]
    },
    {
        sectionTitle: "open source",
        sectionData: [
            {
                logo: liplLogo,
                label: "Live Plot",
                isTerminated: false,
                markDown: liplMd,
            },
            {
                logo: svgComposerLogo,
                label: "svg composer",
                isTerminated: false,
                markDown: svgComposerMd,
            },
        ]
    },
    {
        sectionTitle: "alumni - projects",
        sectionData: [
            {
                logo: sonosLogo,
                label: "sonos.com",
                isTerminated: false,
                markDown: sonosMd,
            },
            {
                logo: snipsLogo,
                label: "snips.ai",
                isTerminated: false,
                markDown: snipsMd,
            },
            {
                logo: bonemetrixLogo,
                label: "bonemetrix",
                isTerminated: false,
                markDown: bonemetrixMd,
            }
        ]
    },
    {
        sectionTitle: "alumni - schools",
        sectionData: [
            {
                logo: school42Logo,
                label: "school 42 - paris",
                isTerminated: false,
                markDown: school42Md,
            },
            {
                logo: schoolHECLogo,
                label: "HEC - track digital entrepreneur",
                isTerminated: false,
                markDown: schoolHECMd,
            }
        ]
    }
];

export default sectionItemsData;