import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useEffect, useState } from 'react';

import './InfoOrContact.css';
import infoMarkDown from './../../resources/markdowns/info.md';
import contactMarkDown from './../../resources/markdowns/contact.md';

function InfoOrContact({ isInfo }) {
    const [markDownAsText, setMarkDownAsText] = useState('');

    useEffect(() => {
        fetch(isInfo ? infoMarkDown : contactMarkDown)
        .then(r => r.text())
        .then(text => {
            setMarkDownAsText(text);
        });
    }, [isInfo]);

    return (
        <main className='main-info'>
            <div className='markdown-wrapper'>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>{markDownAsText}</ReactMarkdown>
            </div>
        </main>
    );
}

export default InfoOrContact;
