import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useEffect, useState } from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Detail.css';

function Detail({ detailData, onClose }) {
    const { label, logo, markDown } = detailData || { label: '', logo: '' };
    const [markDownAsText, setMarkDownAsText] = useState('');

    useEffect(() => {
        fetch(markDown)
        .then(r => r.text())
        .then(text => {
            setMarkDownAsText(text);
    });
    }, [markDown]);

    return (
        <div className='detail-wrapper'>
            <FontAwesomeIcon onClick={onClose} className='close-btn' size='2x' icon={faClose}></FontAwesomeIcon>
            <div className='detail-container'>
                <div className='img-wrapper'>
                    <img className='detail-section-item-img' src={logo} alt={label + ' company logo'}></img>
                </div>
                <div className='markdown-wrapper'>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>{markDownAsText}</ReactMarkdown>
                </div>
            </div>
        </div>
    );
}

export default Detail;
