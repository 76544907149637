import React from 'react';
import './SectionTitle.css'

function SectionTitle({ title }) {
    return (
        <div className='section-wrapper'>
            <div className='divider' />
            <div className='section-title'>{ title }</div>
            <div className='divider' />
        </div>
    );
}

export default SectionTitle;
