import React from 'react';
import { Fragment, useEffect } from 'react';
import { useState } from 'react';

import SectionItems from '../sectionItems/SectionItems';
import SectionTitle from '../sectionTitle/SectonTitle';
import './Content.css'
import sectionItemsData from './sectionItemsData';
import Detail from '../detail/Detail';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const PAGE_DESCRIPTION = "";

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      padding: '0',
      marginRight: '-50%',
      height: '90vh',
      maxWidth: '90%',
      transform: 'translate(-50%, -50%)',
      opacity: 1,
      'background-color': 'var(--primary-color)',
    },
    
  };

function Content() {
    const [itemIsSelected, setItemIsSelected] = useState(false);
    const [selectedItem, setSelectedItem] = useState({ label: '', logo: '' });

    const onItemClick = (label) => {
        setItemIsSelected(true);

        sectionItemsData.forEach((sectionItemData) => {
            let selectedItemFound = sectionItemData.sectionData.find((sectionItemData) => sectionItemData?.label === label)
            if (!!selectedItemFound) {

                setSelectedItem(selectedItemFound)
            }
        });
    };

    function closeModal() {
        setItemIsSelected(false);
    }

    const onCloseDetail = () => {
        setSelectedItem({ label: '', logo: '' });
        setItemIsSelected(false);
    };

    return (
        <main>
            <Modal
                isOpen={itemIsSelected}
                style={customStyles}
                onRequestClose={closeModal}
                contentLabel="Experience Page Modal"
            >
                <Detail detailData={selectedItem} onClose={onCloseDetail}/>
            </Modal>
            {PAGE_DESCRIPTION.length !== 0 && <div className='hero-title'>{PAGE_DESCRIPTION}</div>}
            {sectionItemsData.map((sectionItems) => (
                <Fragment key={sectionItems.sectionTitle}>
                    <SectionTitle title={sectionItems.sectionTitle} />
                    <SectionItems data={sectionItems.sectionData} onItemClick={onItemClick} selectedItem={selectedItem}/>
                </Fragment>

            ))}
        </main>
    );
}

export default Content;
